.header {
  background: #41342a;

  img {
    width: 100%;
    height: 100%;
  }
}

.logo {
  max-width: 18%;
  margin: auto;
}

.main {
  font-family: 'Alice', serif;

  h1 {
    color: #3e3026;
    text-transform: uppercase;
    font-size: 3.8em;
    margin-bottom: 0.6em;
  }
}

.separator {
  height: 3px;
  width: 200px;
  background: #3e3026;
  margin: 50px auto 20px;
}

.title {
  padding: 20px;
  font-size: 32px;
  background: #ac9788;
  color: #fff;
  margin-bottom: 10px;
}

.smallFormWrapper {
  box-shadow: 1px 1px 5px #ac9788;
  margin-bottom: 3em;
  padding-bottom: 2px;

  input[type='radio'] {
    padding-bottom: 5px;
    font-size: 18px;
  }

  label {
    font-size: 18px;
  }

  button {
    margin: 15px 0 20px 38%;
    font-weight: 600;
    font-family: 'Arial', serif;
    font-size: 26px;
  }
}

.datWrapper {
  box-shadow: 1px 1px 5px #ac9788;
  margin: 0;

  .data {
    padding: 20px;
  }

  p {
    font-size: 22px;
  }

  p:nth-of-type(7) {
    border-bottom: 2px solid #b58257;
    padding-bottom: 20px;
  }

  p:last-of-type {
    font-size: 26px;
  }

  span {
    color: #b58257;
  }
}

.previousDataTitle {
  background: #41342a;
}

.btnConfirm {
  margin: 20px 0;

  button {
    padding: 0 15px;
    font-family: 'Arial', serif;
  }
}
