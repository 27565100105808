.learnMore {
  font-size: 25px;
  font-weight: 600;
  border: 1px solid #b58156;
  background: transparent;
  border-radius: 8px;
  padding: 0 30px 5px;
}

.learnMore a {
  text-decoration: none;
  color: #b58156;
}

.learnMore:hover,
.learnMore:hover a {
  color: #fff;
  background: #b58156;
}
