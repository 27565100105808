.brown {
  background: #412f22;
  padding: 2rem 0 1.2rem;
}

.loginBtn {
  border-radius: 8px;
  background: #b58156;
  padding: 10px;
  white-space: nowrap;

  &:hover {
    background: #dd9f6b;
  }

  span {
    margin-left: 15px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 992px) {
  .nav {
    flex-flow: column nowrap;
    text-align: center;
  }
}
