.carImage {
  position: relative;
  width: 285px;
  height: 285px;
  margin: auto;

  &:hover img {
    border: 5px solid #fa8072;
    padding: 5px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid transparent;
    padding: 5px;
    transition: 0.3s;
  }

  &:hover button {
    background: #b58156;
  }

  button {
    transition: all 0.2s;
    position: absolute;
    bottom: 10%;
    left: 37%;
    background: #41342a;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-size: 25px;
    padding: 10px 15px;
    &:hover {
      background: salmon;
    }
  }
}

.carInfo {
  font-size: 28px;
  text-align: center;

  p {
    margin: 0;
  }
}

.modal {
  max-width: 80%;
}

.brandImage {
  border: 5px solid #f2a66f;
  border-radius: 50%;
  padding: 8px;
  margin: 0 30px;
  position: relative;
  width: 285px;
  height: 285px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.separator {
  width: 35%;
  height: 5px;
  background: #ac9788;
  border-radius: 20px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brandTitle {
  padding-top: 20px;

  h2 {
    color: #948d88;
    font-size: 40px;
  }
}

.carDetailsImages {
  img {
    width: 100%;
    height: 100%;
    padding: 20px;
    object-fit: fill;
    margin-bottom: 3em;
  }
}

// .sliderImage {
//   height: 400px;

// }

.carDetailsImages {
  button {
    &::before {
      opacity: 1;
      color: #b58156;
    }
  }
}
