.intro,
.bookTable {
  font-family: 'Alice', serif;

  img {
    width: 100%;
    height: 100%;
  }
}

.restaurantPhoto {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .transparentEntry {
    position: absolute;
    bottom: 0;
    width: 100%;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    background-color: #43352ab7;
    text-align: center;
    height: 330px;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 100px;
      text-transform: uppercase;
    }

    h2:last-of-type {
      color: #f2a66f;
    }
  }
}

.orangeLogo {
  width: 18%;
  margin: auto;
}

.morePhotos {
  background-color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: -127px 0 -75px 0;
  z-index: 1;

  h2 {
    max-width: 65%;
    margin: auto;
  }
}

.menu {
  width: 100%;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  button {
    background-color: #f2a66f;
    position: relative;
    bottom: 4.5em;
    text-transform: uppercase;
    font-size: 24px;
  }
}

.bookTable {
  font-size: 26px;
  line-height: 1.2;

  h1 {
    font-size: 52px;
  }
}

.title {
  font-size: 56px;
  background-color: #ac9788;
  color: #fff;
}

.formWrapper {
  border: 1px solid #ac9788;
  background-color: #fff;
}

.firstGroup {
  margin-left: 2em;
}

.formBody,
.datepicker {
  font-size: 22px;

  select,
  input {
    color: #ae7546;
    font-size: 22px;
    margin-bottom: 15px;
    border-color: #ac9788;
    border-radius: 0;
  }
}

.formActions {
  text-align: right;
  padding: 0.1em 0em 1em;

  button:hover {
    background-color: #cfbcad;
  }
}

.datePicker {
  position: relative;

  input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    border-radius: 0;
    border: 1px solid #ac9788;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &:after {
    content: '';
    display: block;
    background: url('../../images/calendar-alt.svg') no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 15px;
  }
}

.dateField {
  position: relative;
  margin-bottom: 1.4em;

  input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    border-radius: 0;
    border: 1px solid #ac9788;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.restaurantMap {
  width: 1282px;
  height: 699px;
}

.saloonTables,
.barSeatsContainer {
  position: absolute;
  top: 0;
  bottom: 0;
}

.barSeat {
  position: absolute;
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: transparent;
  background: none;

  &:hover {
    background: url('./../../images/restaurant/tables/seat.png') no-repeat
      center;
  }

  $topBottomLine: 214px;

  &:first-child {
    transform: rotate(90deg);
    top: 91px;
    left: 212px;
  }

  &:nth-child(2) {
    transform: rotate(90deg);
    left: 212px;
    top: 156px;
  }

  &:nth-child(3) {
    left: 277px;
    top: $topBottomLine;
  }

  &:nth-child(4) {
    left: 340px;
    top: $topBottomLine;
  }

  &:nth-child(5) {
    left: 403px;
    top: $topBottomLine;
  }

  &:nth-child(6) {
    left: 466px;
    top: $topBottomLine;
  }

  &:nth-child(7) {
    left: 529px;
    top: $topBottomLine;
  }

  &:nth-child(8) {
    left: 592px;
    top: $topBottomLine;
  }

  &:nth-child(9) {
    left: 654px;
    top: $topBottomLine;
  }

  &:nth-child(10) {
    transform: rotate(270deg);
    left: 735px;
    top: 156px;
  }

  &:last-child {
    transform: rotate(270deg);
    left: 735px;
    top: 91px;
  }
}

.barSeatActive {
  background: url('./../../images/restaurant/tables/seat.png') no-repeat center;
}

.saloonTable4 {
  position: absolute;
  width: 126px;
  height: 126px;
  cursor: pointer;
  font-size: 0.0009px;
  color: transparent;
  background: none;

  &:hover {
    background: url('./../../images/restaurant/tables/table_big.png') no-repeat
      center;
  }

  &:nth-child(1) {
    top: 306px;
    left: 254px;
  }

  &:nth-child(2) {
    top: 306px;
    left: 419px;
  }

  &:nth-child(3) {
    top: 306px;
    left: 570px;
  }
  &:nth-child(4) {
    top: 468px;
    left: 323px;
  }
  &:nth-child(5) {
    top: 468px;
    left: 507px;
  }
}
.saloonTable4Active {
  background: url('./../../images/restaurant/tables/table_big.png') no-repeat
    center !important;
}

.saloonTable2 {
  position: absolute;
  width: 121px;
  height: 101px;
  cursor: pointer;
  font-size: 0.0009px;
  color: transparent;
  background: transparent;

  &:hover {
    background: url('./../../images/restaurant/tables/table_bar_wide.png')
      no-repeat center;
  }

  &:nth-child(1) {
    top: 529px;
    left: 697px;
  }

  &:nth-child(2) {
    top: 529px;
    left: 840px;
  }
}
.saloonTable2Active {
  background: url('./../../images/restaurant/tables/table_bar_wide.png')
    no-repeat center !important;
}

.saloonTable7 {
  position: absolute;
  width: 33px;
  height: 119px;
  cursor: pointer;
  font-size: 0.0009px;
  color: transparent;
  background: transparent;

  &:hover {
    background: url('./../../images/restaurant/tables/table_bar_short.png')
      no-repeat center;
  }

  &:nth-child(1) {
    top: 25px;
    left: 845px;
    width: 111px;
    height: 201px;
  }

  &:nth-child(2) {
    top: 248px;
    left: 845px;
    width: 112px;
    height: 201px;
  }
}

.saloonTable7Active {
  background: url('./../../images/restaurant/tables/table_bar_short.png')
    no-repeat center !important;
}

.saloonTable8 {
  position: absolute;
  width: 32px;
  height: 249px;
  cursor: pointer;
  font-size: 0.0009px;
  color: transparent;
  background: transparent;

  &:hover {
    background: url('./../../images/restaurant/tables/table_long.png') no-repeat
      center;
  }

  &:first-child {
    top: 350px;
    left: 61px;
    width: 124px;
    height: 269px;
  }
}

.saloonTable8Active {
  background: url('./../../images/restaurant/tables/table_long.png') no-repeat
    center !important;
}

.chefs {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.terraceTables {
  position: absolute;
  cursor: pointer;
  width: 89px;
  height: 90px;

  &:hover {
    background: url('./../../images/restaurant/tables/table_small.png')
      no-repeat center;
  }

  &:first-child {
    top: 116px;
    left: 993px;
  }

  &:nth-child(2) {
    top: 116px;
    left: 1137px;
  }

  &:nth-child(3) {
    top: 251px;
    left: 995px;
  }

  &:nth-child(4) {
    top: 251px;
    left: 1137px;
  }

  &:nth-child(5) {
    top: 535px;
    left: 993px;
  }

  &:nth-child(6) {
    top: 535px;
    left: 1131px;
  }
}

.terraceTablesActive {
  background: url('./../../images/restaurant/tables/table_small.png') no-repeat
    center;
}

.chefs {
  font-family: 'Alice', serif;
  text-align: center;

  h1 {
    font-size: 56px;
  }

  .chefPhotos {
    img {
      object-fit: center;
      border-radius: 50%;
      border: 30px solid #d2b399;
    }
  }
}

.chef_frame {
  width: 100%;
  height: 0 !important;
  padding-bottom: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}

.chefEntry {
  h2::after {
    content: '';
    border-bottom: 12px solid #b58156;
    display: block;
    border-radius: 8px;
    width: 40%;
    margin: 4px auto 24px;
  }

  p {
    color: #b58156;
    line-height: 1.1;
    font-size: 20px;
  }
}

.modal {
  min-width: 85%;
}

.modalBody {
  position: relative;
  overflow: hidden;
  background-color: #fff;

  &:before {
    content: ' ';
    background: url('./../../images/restaurant/menu-background.png') no-repeat;
    background-size: cover;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.photoWrap {
  width: 100%;
}
