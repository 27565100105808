.intro {
  padding: 145px 0;
  position: relative;
}

.moonlightWrapper {
  background: #3223188c;
  padding: 6rem 15rem;
  position: relative;
}

.moonlightTransparent {
  margin: 1.8em 0;
}

.spa {
  padding-bottom: 150px;
}

.moonlightTransparent img,
.aboutHotel img,
.spa img,
.restaurant img,
.roomsAndFlats img,
.sportBar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.moonlightWrapper {
  background-image: url('../../images/frame.png');
  background-size: 1300px 285px;
  background-position: center center;
  background-repeat: no-repeat;
}

.moonlightTransparent {
  width: 65%;
  padding: 120px 0;
  margin: auto;
}

.moonlightTransparent:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 25%;
  height: 50%;
  border-top: 1px solid #0000cc;
}

.moonlightWrapper span {
  font-size: 7vw;
  white-space: nowrap;
  color: #f2a66f;
  position: absolute;
  top: 24%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.moonlightWrapper span:last-child {
  color: #fff;
  top: 73%;
  letter-spacing: 0.1em;
  font-size: 6vw;
}

.wrap {
  position: relative;
  top: 52px;
  left: 2px;
}

.bookingContainer {
  background: #3d2e21d2;
  text-align: center;
  padding: 30px 0 20px;
  color: #fff;
  font-size: 20px;
  position: relative;
  transform: translateY(100%);
}

.dates {
  position: relative;
}

.dates p + div > div {
  position: relative;
  max-width: 150px;
}

.dates p + div > div:after {
  cursor: pointer;
  content: '';
  display: block;
  background: url('../../images/calendar-alt.svg') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.dates input {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  outline: none;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  padding-right: 40px;
}

.personsCount input {
  width: 60px;
  height: 50px;
  padding-left: 12px;
}

.bookingSection p {
  color: #fff;
}

.aboutHotel {
  background: #fff;
  padding: 125px 150px;
}

.hotelPhoto {
  max-width: 1230px;
  border: 3px solid #000;
  padding: 25px 45px 45px 25px;
}

.aboutHotelImage {
  position: relative;
  left: 5em;
  bottom: 2.8em;
}

.articleEntry p {
  color: #b58156;
  font-size: 28px;
  line-height: 1.2;
}

.manager {
  text-align: right;
  padding-left: 2em;
}

.manager p:last-child {
  font-family: 'initial', Arial, Helvetica, sans-serif;
  color: #b58156;
  position: relative;
  bottom: 1.5em;
}

.leftColumn {
  background: #ac9788;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rightColumn {
  background: #ac9788;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.umbrella {
  max-width: 685px;
  height: 442px;
  padding: 0;
}

.poolEntry div:first-child,
.spaEntry div:first-child {
  position: relative;
  top: 2.8em;
}

.poolEntry {
  padding-left: 0;
  padding-bottom: 30px;
}

.poolEntry p,
.spaEntry p {
  border: 3px solid #000;
  border-left: none;
  height: auto;
  color: #b58156;
  font-size: 28px;
  line-height: 1.2;
  padding: 50px 140px 50px 40px;
}

.spaEntry {
  padding: 0;
  padding-bottom: 30px;
  width: 50%;
}

.spaEntry p {
  border-left: 3px solid #000;
  border-right: none;
}

.spaEntry div:first-child {
  left: 2em;
}

.roomsTitle {
  background: #41342a;
  color: #fff;
}

.roomsTitle h1 {
  font-size: 56px;
  text-transform: uppercase;
}

.rooms {
  padding-bottom: 140px;
}

.room {
  position: relative;
}

.room div {
  position: absolute;
  padding: 15px 0 0 20px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
}

.separator {
  background: #000;
  height: 2px;
  width: 50px;
  display: inline-block;
  margin-left: 6px;
}

.room p {
  position: absolute;
  bottom: -18px;
  left: 12px;
  color: #2d2015;
  font-size: 28px;
  padding: 10px 15px;
}

.rooms .room:last-child p {
  background: #cac5c5b7;
  width: 95%;
}

.rooms img {
  box-shadow: 1px 1px 8px grey;
}

.restaurant h1 {
  font-size: 56px;
  color: #41342a;
  text-transform: uppercase;
}

.restaurantPhoto {
  position: relative;
  top: 30px;
}

.restaurantEntry {
  font-size: 22px;
  margin: auto;
}

.restaurantEntry p:first-child {
  margin: 0;
}

.restaurantEntry p:first-child,
.restaurantEntry div:first-child {
  margin-top: 25px;
}

.restaurantEntry div:last-child {
  padding: 0;
}

.barEntry button {
  margin-top: 28px;
}
