.image {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.summery {
  padding-top: 2em;
  background: #dfd7d1;

  h1 {
    padding-left: 0.8em;
  }

  p {
    max-width: 85%;
    margin: 1.5em auto 1em;
    font-size: 24px;
  }
}

.countDetails {
  border-top: 1px solid #7e746b;
  border-bottom: 1px solid #7e746b;
  max-width: 84%;
  margin: auto;

  img {
    padding-right: 10px;
  }

  div:first-child {
    border-right: 1px solid #7e746b;
  }

  div {
    padding: 5px;
    font-size: 24px;
  }
}

.typeView {
  p {
    margin-bottom: 15px;
    margin: 15px 36px;
  }

  span {
    height: 0.6em;
    width: 0.6em;
    background: #b58156;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}

.roomActions {
  padding-left: 30px;
  button {
    padding: 3px 20px;
    font-family: 'Arial', serif;
  }
}

.roomContainer {
  max-width: 87%;
  margin: 70px auto;
  padding-bottom: 40px;
  border-bottom: 1px solid #b58156;
}

.lastRoom {
  border-bottom: none;
  padding-bottom: 0;
}
