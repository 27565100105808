.titleContainer {
  background: #645a52e0;
  position: absolute;
  width: 60%;
  top: 50%;
  transform: translateY(-50%);
}

.textWrapper {
  padding: 5% 0 0 25%;
}

.box {
  height: 110px;
  border: 2px solid #fccead;
  border-left: 0;
  border-top: 0;
  margin-right: 30px;
}
.box .box-border {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}
.box .box-border * {
  margin: 0 1.5rem;
  flex: 1 0 auto;
  position: relative;
  transform: translate(0, -50%);
}
.box .box-border:after {
  content: '';
  border-top: 2px solid #fccead;
  display: block;
  width: 100%;
}
.box-border h2 {
  font-size: 60px;
  color: #b58156;
  margin-left: 0 !important;
}

.box_headline {
  font-size: 60px;
  font-weight: 600;
  color: #b58156;
  margin: 0;
  font-family: 'Alice';
  margin-left: 0 !important;
}
.box_content {
  color: #fff;
  font-size: 3vw;
  margin: 0;
  margin-top: -50px;
  text-transform: uppercase;
}

.hotelSpaText {
  color: #fff;
  letter-spacing: 0.1em;
  font-size: 3vw;
  margin: -10px 0 0 0;
  padding: 0;
}
