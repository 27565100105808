.heading {
  text-align: center;

  h2 {
    color: #fff;
    font-size: 90px;
  }
}

.roomImages {
  box-shadow: 1px 1px 5px gray;
  position: relative;
  bottom: 100px;
  text-align: center;
  background-color: #fff;

  h2 {
    text-align: center;
    font-size: 40px;
  }

  &::before {
    content: '';
    border-top: 2px solid #8c857f;
    height: 3px;
    display: inline-block;
    width: 20%;
    margin: auto;
  }
}

.RoomDetails {
  h2 {
    font-size: 40px;
    border-bottom: 2px solid #8c857f;
    padding-bottom: 14px;
  }
}

.dateRange {
  h2 {
    font-size: 40px;
    padding-bottom: 14px;
  }
}

.capacity,
.comfort,
.viewOptions {
  border-bottom: 2px solid #8c857f;
}

.comfort {
  display: flex;
  align-items: baseline;

  .detailsText {
    max-width: 60%;
    font-size: 20px;
    color: rgb(51, 51, 51);
  }
}

.detailsTitle {
  font-size: 26px;
  display: inline-block;
  padding-right: 1.5em;

  svg {
    color: #b58156;
  }
}

.viewOptions {
  input[type='checkbox'] {
    font-size: 24px;
  }

  label {
    font-size: 20px;
  }
}

.viewCheckboxes {
  padding-left: 35px;
}
