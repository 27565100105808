.roomsAndFlats {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.roomsTitle {
  background: #41342a;
  color: #fff;

  h1 {
    font-size: 56px;
    text-transform: uppercase;
  }
}

.rooms {
  padding-bottom: 140px;
}

.room {
  position: relative;
}

.room div {
  position: absolute;
  padding: 15px 0 0 20px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
}

.separator {
  background: #000;
  height: 2px;
  width: 50px;
  display: inline-block;
  margin-left: 6px;
}

.room p {
  position: absolute;
  bottom: -18px;
  left: 12px;
  color: #2d2015;
  font-size: 28px;
  padding: 10px 15px;
}

.rooms .room:last-child p {
  background: #cac5c5b7;
  width: 95%;
}

.rooms img {
  box-shadow: 1px 1px 8px grey;
}
