.greeting {
  h2 {
    color: #75574d;
    font-size: 40px;
    margin-bottom: 30px;

    &:after {
      content: '';
      border-bottom: 5px solid #ac9688;
      display: block;
      width: 25%;
      margin: auto;
      padding-top: 15px;
    }
  }
}

.bookingsTitle {
  text-transform: uppercase;
  color: #4c4037;
  h1 {
    font-size: 45px;
  }
}

.exit {
  margin-top: 30px;
  button {
    &:hover {
      background-color: #bea796;
    }
  }
}
