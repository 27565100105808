// .svg {
//   position: absolute;
//   top: 0.5em;
//   right: 1.2em;
//   color: #fccead;
//   cursor: pointer;
// }

.parent {
  padding: 5px;
  position: absolute;
  top: 0.5em;
  right: 1.2em;
  color: #fccead;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
