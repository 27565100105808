.transferTitle {
  font-family: 'Alice', serif;
  background: #41342ad8;
  padding: 35px 0 45px;
  margin-top: 135px;
  color: #fff;
}

.transferTitle h1 {
  font-size: 8em;
  text-transform: uppercase;
  margin: 0;
}

.transferTitle h3 {
  color: #f2a66f;
  font-size: 38px;
}

.transferText {
  position: relative;
}

.transferEntry {
  top: 2em;
  font-size: 28px;
  background: #fff;
  text-align: center;
  position: relative;
}

.transferEntry p {
  padding: 20px 160px;
}

.carRent {
  margin: 2em 0 5em;
}

.carRent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carsTitle {
  text-transform: uppercase;
  background: #41342a;
  color: #fff;
  text-align: center;
  padding: 15px;

  h2 {
    font-size: 50px;
  }
}

.typeCars {
  color: #ac9788;
  padding-bottom: 45px;

  h2 {
    font-size: 38px;
    margin: 0;
  }
}

.separator {
  width: 210px;
  height: 5px;
  background: #ac9788;
  display: inline-block;
  border-radius: 20px;
}

.sportCars {
  max-width: 80%;
  margin: auto;
  border-bottom: 5px solid #d4c9c1;
  padding-bottom: 55px;
}

.addBorder {
  border-bottom: 5px solid #d4c9c1;
  padding-bottom: 55px;
}

.vanModels {
  margin-bottom: 60px;
}

//  FORM

.formTitle {
  background: #ac9788;
  text-align: center;
  padding: 20px 0;
  margin: 0;
  h1 {
    color: #fff;
    font-size: 56px;
  }
}

.seats {
  text-align: right;
  input {
    width: 30%;
    float: right;
  }
}

.bookingForm {
  border: 1px solid #ac9788;
  border-top: none;
  padding: 25px;

  label {
    display: block;
  }
}

.dateFiled {
  position: relative;

  input {
    width: 78%;
    height: 45px;
    padding-left: 10px;
    margin-bottom: 1.4em;
  }

  &:after {
    content: '';
    display: block;
    background: url('../../images/calendar-alt.svg') no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 110px;
  }
}

.priceSubmit {
  color: #ac7141;
  font-size: 32px;

  button {
    padding: 10px 30px;
    border: none;
    background: #ac9788;
    color: #fff;
    transition: transform 0.4s;
  }
  button[type='submit']:hover {
    background-color: #ccb2a0;
  }

  .warning {
    font-size: 20px;
  }
}

.bookCar {
  margin: 4em auto;
}
