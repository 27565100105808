.header {
  margin-bottom: 3rem;
}

.semiTransparentTitle {
  background-color: #3b312ad0;

  h1 {
    margin-top: 4.56rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 3.3vw;
    padding: 26px;
    font-family: 'Alice', serif;
  }
}

.headerEntry p,
.main p {
  color: #fff;
  text-align: right;
  font-size: 28px;
  line-height: 1.2;
}

.roomImage {
  z-index: 1;
  div {
    position: relative;
  }

  h2 {
    position: absolute;
    bottom: -4%;
    left: -1.2%;
  }
}

.headerEntry h2,
.roomImage h2 {
  color: #f2a66f;
  white-space: nowrap;
  font-size: 4.62vw;
}

.headerEntry {
  background-color: #645a52;
  position: absolute;
  bottom: -3rem;
  z-index: 1;
  font-family: 'Alice';
  padding-bottom: 2rem;

  span {
    font-size: 2vw;
  }
}

.main {
  margin-top: -8.125rem;
  background-color: #645a52;
  padding: 10.62rem 2rem 2rem;
  z-index: 0;

  p {
    text-align: initial;
  }
}

.pools {
  margin: 20px 20px 20px 0;
  border: 2px solid #000;
  border-left: none;
  height: auto;
  color: #b58156;
  font-size: 28px;
  line-height: 1.2;
  position: relative;
  padding: 50px 140px 50px 50px;

  &:after {
    content: '';
    border-bottom: 2px solid #000;
    display: block;
    position: absolute;
    width: 41px;
    bottom: -2px;
    right: 100%;
  }

  img {
    position: absolute;
    bottom: 17.3rem;
    right: 16.78rem;
  }
}

.spaEntry {
  border-left: 2px solid #000;
  border-right: none;
  margin: 0;

  &:after {
    width: 0;
  }

  img {
    position: absolute;
    bottom: 17.3rem;
    left: 167px;
  }
}

@media only screen and (max-width: 1400px) {
  .pools img {
    right: 8.1rem;
    bottom: 23rem;
  }

  .spaEntry img {
    left: 1em;
    bottom: 25.5rem;
  }
}
