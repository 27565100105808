.loginForm {
  position: relative;

  h2 {
    text-align: center;
    text-transform: uppercase;
    color: #fccead;
    margin-left: auto;
  }
}

.loginBody {
  &:before {
    content: '';
    display: block;
    border-top: 1px solid #eda36d;
    width: 90%;
    margin: auto;
  }
}

.formActions {
  margin-top: 2em;
  button {
    background: transparent;
    font-size: 22px;
  }
}

button.btnActive {
  border: 1px solid #eda36d;
  color: #eda36d;
}

button.btnInactive {
  border: 1px solid #927c6b;
  color: #927c6b;
}

.input {
  padding: 50px 0 40px;

  input[type]:not([type='checkbox']) {
    border: none;
    outline: none;
    background: none;
    border-radius: 0;
    border-bottom: 1px solid #eda36d;
    color: #fff;
    margin-bottom: 1em;
    font-size: 20px;
    box-shadow: none;
  }

  input:focus {
    background: none;
    border-radius: 0;
    color: #eda36d;
    box-shadow: none;
  }

  input::placeholder {
    color: #eda36d;
  }

  input[type='checkbox'] {
    border: 1px solid #fccead;
    background: transparent;
    border-radius: 0;
    font-size: 20px;
  }

  label {
    color: #fccead;
  }
}

.register {
  text-align: right;
}

.passwordField {
  position: relative;
}

.formButtons {
  margin-right: 4em;
  button {
    border-radius: 0;
  }

  .forgottenPassword {
    padding: 0;
    background: transparent;
    color: #fccead;
    text-decoration: underline;
    font-size: 20px;

    margin-bottom: 1em;
    white-space: nowrap;
    text-align: right;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }

    button {
      width: 70%;
      font-size: 26px;
    }
  }
}

.submit {
  text-align: center;
  padding-top: 10px;
  button {
    border-radius: 0;
    padding: 5px 15px;
    font-size: 26px;
    font-family: 'Arial', serif;
    width: 30%;
  }
}

.feedback {
  background-color: #f8d7da;
  padding: 10px 10px 10px 15px;
  border-radius: 5px;
}

.forgotten {
  margin-left: 40%;
  white-space: nowrap;
}
