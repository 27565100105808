.gallery {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.firstRow {
  margin-bottom: 2.5rem;
}

.firstRow,
.secondRow {
  margin-right: 20px;

  .galleryImage:first-child {
    margin-right: 2.5rem;
  }
}

.secondRow {
  margin-bottom: 1.3rem;
}

.boxBlue {
  height: 250px;
}

.sideLine {
  position: relative;
  height: 420%;
  background: #645a52e0;
  z-index: 2;
  top: -75%;
}

.thirdRow {
  div + div {
    margin-left: 2.5rem;
  }
}

.small {
  padding-left: 20px;
}

.maxZoom {
  width: 100%;
}
