.btn {
  border: none;
  color: #fff;
  background: #b58156;
  border-radius: 8px;
  padding: 10px 20px;
  white-space: nowrap;
  font-family: 'Arial', serif;

  .icon {
    font-size: 26px;
  }
}

.btn:hover {
  background: #f2a56f;
}
