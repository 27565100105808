.box {
  border: 3px solid #fccead;
  margin-right: 30px;

  & .headline {
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    & .before,
    & .after {
      border-top: 3px solid #fccead;
      display: block;
      width: 100%;
    }

    & .content {
      margin: 0 1.5rem;
      flex: 1 0 auto;
      position: relative;
      transform: translate(0, -50%);
    }
  }

  &.skip_left_border {
    border-left: 0;

    .headline .content {
      margin-left: 0;
    }
  }

  &.skip_right_border {
    border-right: 0;

    .headline .content {
      margin-right: 0;
    }
  }

  &.headline {
    border-top: 0;
  }

  &.headline_bottom {
    border-bottom: 0;
  }
}
