/* Remove the margin after the footer is finished */

.footer {
  background: #412f22;
  padding: 4rem 0 1.2rem;
  position: relative;
  overflow: hidden;
}

.moonlightLogo {
  position: absolute;
  font-size: 15vw;
  font-weight: bolder;
  width: 100%;
  top: 45%;
  color: #433225;
  transform: translateY(-50%);
  text-align: center;
  user-select: none;
}

.footerContent {
  z-index: 1;
}

.footer-entry p {
  color: #fff;
  font-size: 24px;
  margin: 0;
  font-family: "Alice", serif;
}

.social {
  display: flex;
  flex-direction: column;
  padding: 20px 8px;
  background: #b57446;
  border-radius: 30px;
}
