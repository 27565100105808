.modal-dialog {
  min-width: 34vw;
}

.modal-header {
  border-bottom: none;
  .btn-close {
    background-color: #eda36d;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}

.modal-content {
  background: #5c5148ea;
}

.invalid-feedback {
  background-color: #f8d7da;
  padding: 10px 0px 10px 15px;
  border-radius: 5px;
}
