.conclusion {
  color: #f2a66f;
  font-size: 58px;
}

.separatorBig {
  background: #000;
  height: 3px;
  width: 100%;
  display: inline-block;
}
