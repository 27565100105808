.rooms {
  font-family: 'Alice', serif;
  border: 2px solid #868786;
  max-width: 80%;
  margin-bottom: 5px;

  .cheatBorder {
    height: 2px;
    background: #fff;
    max-width: 80%;
    margin: -2px auto;
    position: relative;
  }
}

.lines {
  .roomsTitle h2 {
    font-size: 32px;
  }
}

.title {
  position: relative;

  h1 {
    font-size: 80px;
    text-transform: uppercase;
    background: #fff;
    top: -50px;
    position: absolute;
  }
}

.checkAvailability {
  background: #645a51;
  border-top: 1px solid #868786;
  padding: 20px 0 30px;
  margin-bottom: 65px;

  p {
    color: #fff;
  }

  h2 {
    color: #fff;
    text-transform: uppercase;
    padding: 1.4em 1em 0 0;
  }

  h2::after {
    content: '';
    display: block;
    width: 90%;
    margin-left: 1em;
    border-radius: 8px;
    border-bottom: 5px solid #b58156;
  }

  button {
    padding: 5px 25px;
    font-size: 26px;
    font-family: 'Arial', serif;
  }
}

.dates {
  position: relative;
}

.dates p + div > div {
  position: relative;
  max-width: 150px;
}

.dates p + div > div:after {
  cursor: pointer;
  content: '';
  display: block;
  background: url('../../images/calendar-alt.svg') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.dates input {
  cursor: pointer;
  border: 0;
  box-shadow: none;
  outline: none;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  padding-right: 40px;
}

.personsCount input {
  width: 60px;
  height: 50px;
  padding-left: 12px;
}
