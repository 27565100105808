.contactInfo {
  background: #645a52;
  padding: 40px 120px 40px 60px;
  color: #fff;
  font-size: 24px;
}

.contactInfo p {
  padding-left: 5px;
}

.address p:not(:first-child) {
  padding-left: 35px;
}

.phoneEmail p:first-child {
  border-top: 1px solid #fccead;
  margin: 0;
}

.phoneEmail p {
  border-bottom: 1px solid #fccead;
  padding: 30px 0;
}
.formWrapper {
  position: relative;
  background: #ac9788;
  border: 2px solid transparent;
}

.formWrapper:after,
.formWrapper:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.formWrapper:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #ac9788;
  border-width: 30px;
  margin-top: -200px;
}
.formWrapper:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: transparent;
  border-width: 33px;
  margin-top: -33px;
}

.form {
  padding: 30px 50px;
}

.form input {
  min-height: 50px;
}

.form input,
.form textarea {
  margin-bottom: 20px;
  border-radius: initial;
}

.containerForm {
  max-width: 1200px;
  margin-top: -72px;
  position: relative;
}

.containerForm img {
  padding-right: 5px;
}

.form button[type='submit'] {
  text-transform: uppercase;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  font-size: 30px;
  padding: 0 20px;
  transition: transform 0.4s;
}

.form button[type='submit']:hover {
  transform: scale(1.2);
}

.field_error {
  color: #e3de76;
  margin-top: -15px;
  margin-bottom: 10px;
}
