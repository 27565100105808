.navbar-nav .nav-link .nav-item,
.nav-item a {
  color: #fff;
  font-size: 22px;
}

.nav-link {
  position: relative;
  white-space: nowrap;

  &:hover {
    color: #e9ba94;
  }
}

.nav-link.active:hover {
  color: #e9ba94;
}

.nav-link.active {
  color: #fff;
  transition: 0.3s;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.nav-link.active::after {
  bottom: 0;
  background: #e9ba94;
  height: 5px;
  width: 35%;
  margin: auto;
  display: block;
  transition: 0.3s;
}

.navbar-brand {
  margin: 0 45px;
}

@media only screen and (max-width: 1400px) {
  .nav-item a {
    color: #fff;
    font-size: 22px;
    padding: 0;
    margin-left: 15px;
  }

  .nav-link.active::after {
    bottom: -10px;
  }
}

@media only screen and (max-width: 992px) {
  .nav-link.active::after {
    width: 7%;
  }
}

.navbar-toggler,
.navbar-light {
  border-color: #f2a66f !important;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(242,166,111, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
